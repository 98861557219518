import {useEffect, useState} from "react";
import {cntl} from "../../../util";

const wrapperCN = (inline?: boolean, className?: string) => cntl`
    ${inline ? "flex items-center space-x-3" : "flex flex-col space-y-3"}
    ${className}
`;

const radioCN = cntl`
    rounded-full
    text-primary
    border-0
    shadow
    cursor-pointer
    disabled:opacity-50
    disabled:cursor-default
    outline-none
    focus:ring-0
    focus:ring-offset-0
`;

const labelCN = (disabled?: boolean) => cntl`
    text-black
    mb-0
    pl-2
    ${disabled ? "cursor-default" : "cursor-pointer"}
`;

interface RadioGroupProps<K extends string> {
    name: string;
    buttons: Array<{id: K; text: string; disabled?: boolean}>;
    inline?: boolean;
    selected?: string;
    className?: string;
    onChange?: (selected: K) => void;
}

export const FormRadioGroup = <K extends string>(props: RadioGroupProps<K>) => {
    const {name, buttons, inline, selected, className, onChange} = props;
    const [current, setCurrent] = useState(selected);

    useEffect(() => {
        setCurrent(selected);
    }, [selected]);

    const onRadioSelect = (id: K) => {
        setCurrent(id);
        onChange?.(id);
    };

    return (
        <div className={wrapperCN(inline, className)}>
            {buttons.map(({id, text, disabled}) => (
                <div key={id} className="flex items-center">
                    <input
                        id={id}
                        checked={id === current}
                        type="radio"
                        name={name}
                        disabled={disabled}
                        onChange={e => e.target.value === "on" && onRadioSelect(id)}
                        className={radioCN}
                    />
                    <label htmlFor={id} className={labelCN(disabled)}>
                        {text}
                    </label>
                </div>
            ))}
        </div>
    );
};
