import {Menu, Transition} from "@headlessui/react";
import {Fragment, PropsWithChildren} from "react";
import {cntl} from "../../../util";

const menuCN = (left?: boolean) => cntl`
    absolute
    ${left ? "left-6" : "right-0"}
    bg-white
    rounded
    overflow-hidden
    shadow-lg
    z-40
`;

export const MenuDropdownItems = ({children, open, left}: PropsWithChildren<{open: boolean; left?: boolean}>) => (
    <Transition
        show={open}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        as={Fragment}
    >
        <Menu.Items className={menuCN(left)} static>
            {children}
        </Menu.Items>
    </Transition>
);
