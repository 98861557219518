import React from "react";
import { ChartBarIcon } from "@heroicons/react/solid";
import { motion } from "framer-motion";
import { Icon } from "../../packages/components";

const Header = () => {
  return (
    <div
      className={`h-12 w-full shadow flex items-center px-4 py-4 bg-link display-block`}
    >
      <motion.div
        initial={{ scale: 0.7 }}
        animate={{ scale: 1 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20,
        }}
      >
        <Icon
          Icon={ChartBarIcon}
          size={5}
          className="text-white block hamburger-menu"
        />
      </motion.div>
      <motion.h1
        initial={{ scale: 0.7 }}
        animate={{ scale: 1 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20,
        }}
        className="text-white text-xl m-4"
      >
        <a href="/"> fpl-analitycs</a>
      </motion.h1>
    </div>
  );
};

export { Header };
