import {ComponentType, CSSProperties} from "react";
import {cntl} from "../../../util";
import {Icon} from "../icons/Icon";
import {TextTruncated} from "./TextTruncated";

interface TextIconProps {
    Icon: ComponentType<{className?: string; style?: CSSProperties}>;
    iconSize?: number;
    iconColor?: string;
    iconClassName?: string;
    iconRight?: boolean;
    iconStyle?: CSSProperties;
    color?: string;
    spacing?: number;
    text: string;
    textSize?: string;
    textClassName?: string;
    wrapperClassName?: string;
}

export const TextIcon = (props: TextIconProps) => {
    const {
        Icon: IconComponent,
        iconColor,
        iconSize,
        iconClassName,
        textClassName,
        iconRight,
        wrapperClassName,
        color,
        text,
        textSize,
        iconStyle,
    } = props;
    const spacing = props.spacing ?? Math.ceil((iconSize ?? 4) / 4);

    const textCN = cntl`
        font-primary
        text-${textSize ?? "base" /* TODO #166 font and textSize should be inherited from parent, like for color */}
        ${color ? `text-${color}` : ""}
        ${textClassName ?? ""}
    `;

    return (
        <div className={`flex items-center overflow-hidden ${wrapperClassName ?? ""}`}>
            <Icon
                Icon={IconComponent}
                color={iconColor ?? color}
                size={iconSize}
                className={`${iconRight ? `order-2 ml-${spacing}` : `mr-${spacing}`} ${iconClassName ?? ""}`}
                style={iconStyle}
            />
            <TextTruncated className={textCN}>{text}</TextTruncated>
        </div>
    );
};
