import {Menu} from "@headlessui/react";
import {ComponentType, MouseEventHandler} from "react";
import {ButtonItem} from "../../atoms/buttons/ButtonItem";

interface MenuDropdownItemProps {
    Icon?: ComponentType<{className?: string}>;
    text: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
}

export const MenuDropdownItem = ({Icon, text, onClick}: MenuDropdownItemProps) => (
    <Menu.Item>
        <ButtonItem Icon={Icon} text={text} onClick={onClick} />
    </Menu.Item>
);
