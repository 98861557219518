//@ts-nocheck
import React, { FunctionComponent, Suspense, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import routes from "./routes/routes";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Header } from "./common/sidebar/Header";
import Loader from "./common/Loader";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App: FunctionComponent = () => {
  // useEffect(() => {
  //   try {
  //     ezstandalone.define(102, 103, 104, 108, 109, 110, 111, 112, 113, 114);
  //     if (!ezstandalone.enabled) {
  //       ezstandalone.enable();
  //       ezstandalone.display();
  //     } else {
  //       ezstandalone.refresh();
  //     }
  //   } catch (error) {}
  // }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />

      <Router>
        <Header />
        <div>
          <Suspense fallback={<Loader />}>
            <Routes>
              {routes.map(({ path, Component }, id) => {
                return <Route key={id} path={path} element={<Component />} />;
              })}
            </Routes>
          </Suspense>
        </div>
      </Router>
    </QueryClientProvider>
  );
};

export default App;
