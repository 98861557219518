import {Switch as HeadlessSwitch} from "@headlessui/react";
import {cntl} from "../../../util";

const toggleBackgroundCN = (checked: boolean) => cntl`
    ${checked ? "bg-primary" : "bg-black-light"}
    relative
    inline-flex
    items-center
    h-5
    rounded-full
    w-8
    duration-200
    transition-colors
    ease-in-out
    cursor-pointer
`;

const toggleKnobCN = (checked: boolean) => cntl`
    ${checked ? "translate-x-4" : "translate-x-1"}
    inline-block
    w-3
    h-3
    transform
    transition-all
    bg-white
    rounded-full
    duration-200
    ease-in-out
`;

interface ToggleProps {
    text?: string;
    checked: boolean;
    onChange?: (checked: boolean) => void;
    className?: string;
}

export const FormToggle = ({text, checked, onChange, className}: ToggleProps) => (
    <HeadlessSwitch.Group as="div" className={cntl`flex items-center cursor-pointer ${className}`}>
        <HeadlessSwitch
            checked={checked}
            onChange={value => onChange?.(value)}
            className={toggleBackgroundCN(checked)}
            as="div"
        >
            <span className={toggleKnobCN(checked)} />
        </HeadlessSwitch>
        <HeadlessSwitch.Label className="text-black-light text-base ml-2 mb-0 cursor-pointer">
            {text}
        </HeadlessSwitch.Label>
    </HeadlessSwitch.Group>
);
