import {cntl} from "../../../util";

const descriptorCN = cntl`
    py-1
    text-sm
    font-semibold
    uppercase
    font-primary
    text-black-lighter
`;

export const TextDescriptor = ({text, className}: {text: string; className?: string}) => (
    <div className={`${descriptorCN} ${className}`}>{text}</div>
);
