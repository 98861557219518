import {Dialog} from "@headlessui/react";
import {cntl} from "../../../util";

const modalHeaderCN = cntl`
    flex
    border-b
    border-table-x
    py-4
    px-6
    text-lg
    font-medium
    text-black-dark
    bg-gray
    rounded-t
`;

export const ModalHeader = ({text}: {text: string}) => <Dialog.Title className={modalHeaderCN}>{text}</Dialog.Title>;
