import {UploadIcon} from "@heroicons/react/solid";
import {ChangeEventHandler} from "react";
import {Button, Icon, IconSpinner, Text, TextDescriptor} from "../../../base/index";
import {cntl} from "../../../util";

const uploadButtonCN = cntl`
    flex
    border-2
    border-dashed
    border-table-x
    rounded
    w-full
    h-48
    items-center
    justify-center
`;

interface ButtonUploadImageProps {
    /** The text of the Descriptor at the top-left of the image upload button. */
    descriptorText: string;
    /** The text for the Button at the top-right of the image upload button, only visible if `preview` is set. */
    uploadButtonText: string;
    /** The text for the image placeholder, only visible if `preview` is not set. */
    uploadText: string;
    id: string;
    /** A preview image, encoded as data-URL. */
    preview?: string;
    isLoading?: boolean;
    onChange?: ChangeEventHandler<HTMLInputElement>;
}

export const ButtonUploadImage = (props: ButtonUploadImageProps) => {
    const {id, descriptorText, uploadButtonText, uploadText, preview, isLoading, onChange} = props;
    return (
        <label htmlFor={id} className="w-full cursor-pointer mt-3 mb-8">
            <div className="flex items-center justify-between">
                <TextDescriptor text={descriptorText} />
                {preview && <Button Icon={UploadIcon} text={uploadButtonText} />}
            </div>
            <div className={preview ? "" : uploadButtonCN}>
                {isLoading ? (
                    <div className={uploadButtonCN}>
                        <IconSpinner color="primary" />
                    </div>
                ) : preview ? (
                    <div className="rounded overflow-hidden shadow">
                        <img src={preview} alt={id} className="w-full" />
                    </div>
                ) : (
                    <div className="flex flex-col">
                        <Icon Icon={UploadIcon} size={8} color="black-lightest" className="mb-2 mx-auto" />
                        <Text text={uploadText} />
                    </div>
                )}
            </div>
            <input
                id={id}
                name="image"
                type="file"
                accept="image/png, image/jpeg"
                className="sr-only"
                onChange={onChange}
            />
        </label>
    );
};
