import {ReactNode, useState} from "react";
import {usePopper} from "react-popper";
import {cntl} from "../../../util";

const popperCN = cntl`
    bg-black-dark
    text-white
    px-3
    py-1
    rounded
    text-sm
    shadow-lg
    z-30
`;

// TODO Using PropsWithChildren<{content: string}> here would break translation due to the type intersection :thinking:
export const Tooltip = ({content, children}: {content: string; children: ReactNode}) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipRef, setTooltipRef] = useState<HTMLSpanElement | null>(null);
    const [contentRef, setContentRef] = useState<HTMLSpanElement | null>(null);

    const {styles, attributes, update} = usePopper(contentRef, tooltipRef, {
        placement: "top",
        strategy: "fixed",
        modifiers: [
            {
                name: "offset",
                enabled: true,
                options: {offset: [0, 5]},
            },
        ],
    });

    const openTooltip = () => {
        update?.();
        setShowTooltip(true);
    };
    const closeTooltip = () => setShowTooltip(false);

    return (
        <span ref={setContentRef} onMouseEnter={openTooltip} onMouseLeave={closeTooltip}>
            {showTooltip && (
                // TODO styles.popper and attributes.popper should exist, but the typings of "react-popper" are too wide
                <div ref={setTooltipRef} className={popperCN} style={styles["popper"]} {...attributes["popper"]}>
                    {content}
                </div>
            )}
            {children}
        </span>
    );
};
