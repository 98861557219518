import {useEffect, useState} from "react";
import {cntl} from "../../../util";
import {Tooltip} from "../tooltips/Tooltip";

interface TextTruncatedProps {
    content?: string;
    className?: string;
    children?: string;
}

/**
 * Wrapper component for text that automatically truncates it. When truncated, a Tooltip shows the provided content.
 * NOTE: `TruncatedText` is assumed to be a flex child, and so expects a flex container as direct parent. The parent
 * also needs to restrict the width of its children in some way, e.g. with `overflow: hidden` set.
 * @param content The content of the Tooltip.
 * @param className Optional className, provided to the wrapping `div`.
 * @param children The contents that needs to be truncated. Can only be string.
 */
export const TextTruncated = ({content, className, children}: TextTruncatedProps) => {
    const [ref, setRef] = useState<HTMLDivElement | null>(null);
    const [isTruncated, setTruncated] = useState(false);

    const setElementTruncated = (element: HTMLDivElement | null) => {
        if (!element) return;
        setTruncated(element.offsetWidth < element.scrollWidth);
    };

    const onRef = (element: HTMLDivElement | null) => {
        setRef(element);
        setElementTruncated(element);
    };

    useEffect(() => {
        const onResize = () => setElementTruncated(ref);
        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize);
    }, [ref]);

    const truncatedText = (
        <div ref={onRef} className={cntl`truncate ${className ?? ""}`}>
            {children}
        </div>
    );

    return (
        <div className="min-w-0">
            {isTruncated ? <Tooltip content={content ?? children ?? ""}>{truncatedText}</Tooltip> : truncatedText}
        </div>
    );
};
