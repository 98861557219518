import {PropsWithChildren} from "react";
import {cntl} from "../../../util";

const modalFooterCN = cntl`
    flex
    border-t
    border-table-x
    py-4
    px-6
    bg-gray
    rounded-b
    space-x-3
`;

export const ModalFooter = ({children}: PropsWithChildren<{}>) => <div className={modalFooterCN}>{children}</div>;
