import {ComponentType, MouseEventHandler, ReactNode} from "react";
import {cntl} from "../../../util";
import {HoverDark} from "../../abstracts/hovers/HoverDark";
import {Text} from "../typography/Text";
import {TextIcon} from "../typography/TextIcon";

interface ButtonProps {
    text?: string;
    Icon?: ComponentType<{className?: string}>;
    color?: string;
    disabled?: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    className?: string;
    fullWidth?: boolean;
    fullHeight?: boolean;
    hover?: boolean;
    rounded?: boolean;
    right?: boolean;
    large?: boolean;
    active?: boolean;
    id?: string;
    submit?: boolean;
    children?: ReactNode;
}

export const Button = (props: ButtonProps) => {
    const {
        text,
        Icon,
        color,
        disabled = false,
        hover = false,
        right,
        large,
        onClick,
        fullWidth,
        fullHeight,
        rounded = true,
        className,
        active,
        id,
        submit,
        children,
    } = props;

    const buttonCN = cntl`
        flex
        ${color && ["primary", "secondary", "danger", "warning"].includes(color) ? `bg-${color}` : ""}
        ${rounded ? "rounded" : ""}
        ${disabled ? "bg-black-lightest cursor-not-allowed" : "cursor-pointer"}
        ${fullWidth ? "justify-center items-center w-full" : ""}
        ${fullHeight ? "h-full" : ""}
        ${right ? "ml-auto" : ""}
        ${large ? "py-1 px-2" : ""}
        ${active ? "bg-opacity-darker font-semibold" : ""}
        ${className}
    `;

    const content = text ? (
        Icon ? (
            <TextIcon
                Icon={Icon}
                text={text}
                color={
                    color
                        ? ["primary", "secondary", "danger", "warning"].includes(color)
                            ? "white"
                            : color
                        : "black-light"
                }
                wrapperClassName={`${hover ? "px-4 py-2" : "px-2 py-1"}`}
                iconClassName={`${hover && !fullHeight ? "mr-3" : ""}`}
            />
        ) : (
            <div className={`${hover ? "px-4 py-2" : "px-2 py-1"}`}>
                <Text
                    text={text}
                    color={
                        color
                            ? ["primary", "secondary", "danger", "warning"].includes(color)
                                ? "white"
                                : color
                            : "black-light"
                    }
                />
            </div>
        )
    ) : (
        children
    );

    return (
        <button id={id} className={buttonCN} type={submit ? "submit" : "button"} disabled={disabled} onClick={onClick}>
            {hover ? (
                <HoverDark rounded={rounded} className={`${fullHeight ? "flex items-center justify-center" : ""}`}>
                    {content}
                </HoverDark>
            ) : (
                content
            )}
        </button>
    );
};
