import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/solid";
import { ReactNode } from "react";
import {
  ToastContent,
  toast as toastifyToast,
  ToastOptions,
  TypeOptions,
} from "react-toastify";
import { Icon } from "../../atoms/icons/Icon";

const TYPE = toastifyToast.TYPE;

const defaultIconProps = {
  className: "mr-2",
  size: 5,
  Icon: InformationCircleIcon,
  color: "gray",
};
const iconMap: { [k in TypeOptions]: any } = {
  info: <Icon {...defaultIconProps} color="info" />,
  success: (
    <Icon {...defaultIconProps} color="success" Icon={CheckCircleIcon} />
  ),
  warning: (
    <Icon {...defaultIconProps} color="warning" Icon={ExclamationCircleIcon} />
  ),
  error: <Icon {...defaultIconProps} color="danger" Icon={XCircleIcon} />,
  default: <Icon {...defaultIconProps} />,
};

const toastWrapper = (content: ToastContent, options?: ToastOptions) => {
  const contentWithIcon = (
    <>
      {iconMap[options?.type || TYPE.DEFAULT]}
      {content}
    </>
  );
  return toastifyToast(contentWithIcon, options);
};

export const toast = Object.assign(toastWrapper, toastifyToast);

// Inspired by https://github.com/fkhadra/react-toastify/blob/master/src/core/toast.tsx#L107-L115
(["success", "info", "error", "warning", "dark"] as const).forEach(
  (type: any) => {
    //@ts-ignore
    toast[type] = (content: ToastContent, options?: ToastOptions) =>
      toastWrapper(content, { type, ...options });
  }
);
toast.warn = toast.warning;
