import { LazyExoticComponent, lazy } from "react";

interface IRoute {
  path: string;
  Component: LazyExoticComponent<() => JSX.Element>;
}

const Players = lazy(() => import("../pages/Players"));

const MvpTeam = lazy(() => import("../pages/MvpTeam"));

const routes: IRoute[] = [
  { path: "/", Component: Players },
  { path: "mvp", Component: MvpTeam },
];

export default routes;
